import { DynamicPageCategory } from "@strapi/types";
import { joinPaths } from "@utils/urls/url-utils";
import { isNil } from "lodash";

export const routes = {
  home: () => "/",
  notFound: () => "/not-found/",
  contact: () => "/contact/",
  thankYou: () => "/thank-you/",
  companyNews: (page?: number) =>
    joinPaths(["company-news", isNil(page) ? undefined : `/page/${page}`]),
  singleCompanyNews: (slug: string) => joinPaths(["company-news", slug]),
  search: () => "/search/",
  page: (slug: string) => `/${slug}/`,
  dynamicPage: (categorySlug: DynamicPageCategory, slug: string) =>
    joinPaths([categorySlug, slug]),
  dynamicPageListing: (category: DynamicPageCategory) => `/${category}/`,
  blog: (category: string, slug: string) => joinPaths(["blog", category, slug]),
  blogListing: (category?: string, page?: number, tag?: string) =>
    joinPaths([
      "blog",
      tag ? `tag/${tag}` : category,
      isNil(page) ? undefined : `/page/${page}`,
    ]),
  successStory: (slug: string) => joinPaths(["success-stories", slug]),
  successStoriesListing: (industry?: string, page?: number) =>
    joinPaths([
      "success-stories",
      industry ? `industry/${industry}` : undefined,
      isNil(page) ? undefined : `/page/${page}`,
    ]),
  feedsXml: () => "/feeds/blog/rss.xml",
};
