import { FC } from "react";
import { cn } from "utils/tailwind";
import styles from "./NavToggler.module.scss";

type TogglerProps = {
  id: string;
  opened: boolean;
  className?: string;
  onToggle: () => void;
};

export const NavToggler: FC<TogglerProps> = ({
  id,
  opened,
  className,
  onToggle,
}) => {
  const baseClasses = cn(
    `relative block size-[32px] cursor-pointer border-none bg-transparent p-[8px] text-content-primary-on-light lg:hidden`
  );

  return (
    <button
      id={id}
      className={cn(
        baseClasses,
        className,
        styles.toggler,
        opened ? styles.opened : ""
      )}
      onClick={onToggle}
      aria-label="Open Menu"
      aria-expanded={opened ? "true" : "false"}
      aria-haspopup="true"
      aria-controls="menu"
    >
      <div className="relative size-full">
        <span />
        <span />
        <span />
      </div>
    </button>
  );
};
