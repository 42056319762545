import { SVGProps } from "react";

const Search = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.45573 14.1222C3.77383 14.1222 0.789062 11.1374 0.789062 7.45548C0.789062 3.77359 3.77383 0.788818 7.45573 0.788818C11.1376 0.788818 14.1224 3.77359 14.1224 7.45548C14.1224 8.99608 13.5998 10.4146 12.7223 11.5435L17.2117 16.0329L16.0331 17.2114L11.5438 12.722C10.4149 13.5996 8.99632 14.1222 7.45573 14.1222ZM12.4557 7.45548C12.4557 10.2169 10.2172 12.4555 7.45573 12.4555C4.69431 12.4555 2.45573 10.2169 2.45573 7.45548C2.45573 4.69406 4.69431 2.45549 7.45573 2.45549C10.2172 2.45549 12.4557 4.69406 12.4557 7.45548Z"
    />
  </svg>
);

export default Search;
