import Icon from "@atoms/icon/Icon";
import { routes } from "@utils/routes/mapping";
import { cn } from "@utils/tailwind";
import Link from "next/link";
import { FC } from "react";

export type SearchLinkProps = {
  isWhite: boolean;
  className?: string;
  iconClassName?: string;
};

export const SearchLink: FC<SearchLinkProps> = ({
  isWhite,
  className,
  iconClassName,
}) => (
  <Link
    href={routes.search()}
    className={cn(
      isWhite
        ? "text-content-primary-on-dark"
        : "text-content-primary-on-light",
      className
    )}
  >
    <Icon
      name="Search"
      className={cn(
        // 17px - to match nav toggler's height
        "size-[17px]",
        iconClassName
      )}
    />
  </Link>
);
