import { SVGProps } from "react";

const Link = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g>
      <g>
        <g>
          <path
            d="M22.9,3.8L19,11.5c-0.3,0.5-0.8,0.8-1.4,0.7c-0.3-0.1-0.6-0.3-0.7-0.6c-0.1-0.3-0.1-0.7,0-0.9l4-7.9
				l-0.1-0.2c-0.1-0.1-0.3-0.3-0.5-0.3L8.7,6.4c-1.1,0.4-2,1.2-2.2,2.2C6.2,9.9,6.4,11,7.2,11.6l5.4,4.3c-0.9,0.9-2.4,1-3.4,0.2
				l-3.4-2.7c-1.5-1.3-2-3.2-1.4-5.2C4.7,6.4,6.1,4.9,8,4.3l11.5-4.2C19.7,0.1,20,0,20.3,0c0.8,0,1.6,0.4,2.2,1
				C23.2,1.8,23.4,2.9,22.9,3.8z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M16.5,17.9l-0.8,1.6c-0.8,1.7-2.2,2.9-3.9,3.3c0,0-0.1,0-0.1,0L3.9,24c-0.2,0-0.3,0-0.4,0
				c-0.7,0-1.3-0.3-1.8-0.8c-0.7-0.8-1-1.8-0.7-2.8l1.7-5.1c0.1-0.5,0.6-0.9,1.1-0.9c0,0,0.1,0,0.1,0c0.3,0.1,0.6,0.2,0.8,0.5
				c0.2,0.3,0.2,0.6,0.1,0.9L3.1,21c-0.1,0.2,0.1,0.5,0.2,0.6l0.1,0.1l7.9-1.2c1-0.2,1.8-1,2.3-2.1l0.8-1.6c0.4-0.9,0.2-2-0.6-2.7
				L8.7,9.8c0.4-0.4,0.9-0.6,1.5-0.7c0.7,0,1.4,0.2,1.9,0.6l3.2,2.7C16.9,13.8,17.4,16,16.5,17.9z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Link;
